import { AppConfig } from 'src/configuration';
import { I18n } from 'i18n-js';
import de from 'src/i18n/de.json';
import en from 'src/i18n/en.json';
import en001 from 'src/i18n/en-001.json';
import fr from 'src/i18n/fr.json';

const { locale } = AppConfig.runtime;
const i18n = new I18n();

// TODO: In the future implement lazy load => https://github.com/fnando/i18n#updating-translation-store
i18n.store(de);
i18n.store(en);
i18n.store(en001);
i18n.store(fr);
i18n.locale = locale;
i18n.enableFallback = true;

document.addEventListener('app:config:updated', () => (i18n.locale = AppConfig.runtime.locale));

if (AppConfig.inTestEnv) {
  I18n.missingTranslation = (translation, options) => {
    throw new Error(`missingTranslation I18n.t('${translation}', ${JSON.stringify(options)})`);
  };
}

export default i18n;
